<template>
<div class="school">
  <a-spin :spinning="spinning">
<!--    省份城市搜索-->
    <div class="searcherC">
      <div style="display: flex;align-items: center">
        <a-button style="margin-top: -68px">省份:</a-button>
        <a-button style="font-weight:bold;font-family: 微软雅黑;margin-top: -68px" size="small" @click="allChange" >全部</a-button>
        <div class="provinces" >
          <div   v-for="(item,index) in options"  :key="item.code"  >
            <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:provincesStyle == index}" id="provincesBtn"  @click="provincesChange(item.name,index)">{{item.name}}</a-button>
          </div>
        </div>
      </div>
      <a-divider :dashed="true" />
      <div style="display: flex;align-items: center">
        <a-button>城市:</a-button> &nbsp;
        <a-button style="font-weight:bold;font-family: 微软雅黑" size="small" @click="allCity" >全部</a-button>

        <div class="City">
        <span v-for="(item,index) in City.cityList" :key="item.value">
    <a-button style="margin: 5px;font-weight:bold;font-family: 微软雅黑" size="small" :class="{ liBackground:CityStyle == index}" id="cityBtn"  @click="CityChange(item.name,index)">{{item.name}}</a-button>
    </span>
        </div>
      </div>
    </div>

<!--    学校搜索-->
    <div class="Searcher">
      <div class="searchH">
        名称 &nbsp; <a-input v-model="searchValue.name" placeholder="输入名称" style="width: 210px" size="default" />
      </div>
      <div style="padding-left: 15px">
        <a-button @click="onSearch" >查询</a-button>
        <a-button style="margin-left: 10px" @click="searchCancel" >重置</a-button>
      </div>
    </div>
    <div class="school_list">
      <a-button type="primary" @click="()=>{this.schoolAdd = true}" style="margin-bottom: 10px" >
        新增
      </a-button>

<!--      学校列表-->
      <a-table  :columns="columns" :data-source="SchoolList" :rowKey="record => record.id" :pagination="false"
      >
        <span slot="city" slot-scope="text,record">
          {{record.province}}/{{record.city}}
        </span>
        <span slot="operate" slot-scope="text,record">
          <a style="color: #1890ff" @click="Edit(record)">编辑</a>&nbsp;
          <a style="color: #1890ff" @click="delSchool(record.id)">删除</a>
        </span>
      </a-table>
      <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;margin-bottom: -10px">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="schoolPagination" />
      </div>
    </div>




    <!------------------新增弹框----------->
    <a-drawer
        title="新增"
        :width="400"

        :visible="schoolAdd"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{schoolAdd=false; this.spinning=false}"
    >
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" hideRequiredMark>
        <a-form-model-item label="名称" prop="name">
          <a-input placeholder="输入名称" v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="省份" prop="province">
          <a-select placeholder="选择省份" v-model="addForm.province" style="width: 120px"  >
            <a-select-option v-for="(item,index) in options" :key="item.code" :value="item.name" @click="provinceAdd(item.name,index)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="城市" prop="city">
          <a-select placeholder="选择城市" v-model="addForm.city" style="width: 120px"  >
            <a-select-option v-for="item in CityNUM.cityList" :key="item.code" :value="item.name" @click="cityAdd(item.name)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="()=>{schoolAdd=false; this.spinning=false}">
          取消
        </a-button>
        <a-button type="primary" @click="addOk">
          确认
        </a-button>
      </div>
    </a-drawer>
<!--    学校信息修改-->
    <a-drawer
        title="新增"
        :width="400"
        :visible="schoolEdit"
        :body-style="{ paddingBottom: '80px' }"
        @close="()=>{schoolEdit=false; this.spinning=false}"
    >
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" hideRequiredMark>
        <a-form-model-item label="名称" prop="name" >
          <a-input placeholder="输入名称" v-model="editForm.name" />
        </a-form-model-item>
        <a-form-model-item label="省份" prop="province">
          <a-select v-model="editForm.province" style="width: 120px"  >
            <a-select-option v-for="(item,index) in options" :key="item.code" :value="item.name" @click="provinceEdit(item.name,index)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="城市" prop="city">
          <a-select v-model="editForm.city" style="width: 120px"  >
            <a-select-option v-for="item in CityNUM.cityList" :key="item.code" :value="item.name" @click="cityEdit(item.name)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="()=>{schoolEdit=false; this.spinning=false}">
          取消
        </a-button>
        <a-button type="primary" @click="editOk">
          确认
        </a-button>
      </div>
    </a-drawer>
  </a-spin>
</div>
</template>

<script>

import {delSchool, getSchool, getSchoolId, postSchoolAdd, putSchoolEdit} from "@/service/school_api";
import City from '@/assets/city.json'

export default {
  name: "School",
  data(){
    return{
      schoolAdd:false,
      schoolEdit:false,
      spinning:false,
      isShow:10,
      showMore:false,
      SchoolList:[],
      addForm:{
        name:'',
        city:undefined,
        province:undefined
      },
      editForm:{
        name:'',
        city:'',
        province:'',
      },
      rules:{
          name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
          province:[{ required: true, message: '请输入省份', trigger: 'change' }],
          city:[{ required: true, message: '请输入城市', trigger: 'change' }],
      },
      City:'',
      CityNUM:'',
      provinceS:'',
      cityS:'',
      CityStyle:null,
      provincesStyle:null,

      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      options: City ,
      searchValue:{
        name:'',
      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '序号',
          dataIndex: 'number',
          width: '5%',
        },
        {
          title: '名称',
          dataIndex: 'name',
          width: '12%'
        },
        {
          title: '省份/城市',
          scopedSlots: { customRender: 'city' },
          width: '10%'
        },
        {
          title: '操作',
          width: '10%',
          scopedSlots: { customRender: 'operate' },
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"学校管理")
  },
  mounted() {
    this.getSchoolList()
  },
  methods:{
    //获取学校信息
    async getSchoolList(data){
      this.spinning = true
      const result=await getSchool(data)
      if (result.code === 0){
        this.SchoolList = result.data.rows
        this.pagination.total = result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    //分页
    async schoolPagination(){
      this.spinning = true
      const data = {
        name : this.searchValue.name,
        pagenum: this.pagination.current,
        province: this.provinceS,
        city: this.cityS
      }
      await this.getSchoolList(data)

      this.spinning = false
    },
    //新增确认
    addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const edit = await postSchoolAdd(this.addForm)
          if (edit.code === 0){
            const data = {
              name : this.searchValue.name,
              pagenum: this.pagination.current
            }
            await this.getSchoolList(data)
            this.$refs.addItem.resetFields();
            this.$message.success("添加成功")
            this.schoolAdd=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改
    async Edit(ref){
      const id = ref.id
      this.schoolEdit=true;
      const editId = await getSchoolId(id)
      if (editId.code === 0){
        this.editForm = editId.data
      }
      else {
        this.$message.error('获取接口失败'+ editId.message);
      }

    },
    //修改确认
    editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const id = this.editForm.id
          const edit = await putSchoolEdit(id,this.editForm)
          if (edit.code === 0){
            const data = {
              name : this.searchValue.name,
              pagenum: this.pagination.current
            }
            await this.getSchoolList(data)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.schoolEdit=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除学校信息
    async delSchool(id){
      const delId = await delSchool(id)
      if (delId.code === 0){
        this.$message.success('删除成功')
        const data = {
          name : this.searchValue.name,
          pagenum : this.pagination.current
        }
        await this.getSchoolList(data)
      }
    },
    //搜索
    async onSearch(){
      const data = {
        name : this.searchValue.name,
        province: this.provinceS,
        city: this.cityS
      }
      await this.getSchoolList(data)
      this.pagination.current = 1
    },
    //搜索重置
    searchCancel(){
      this.getSchoolList()
      this.searchValue = {}
      this.cityS = ''
      this.provinceS=''
      this.CityStyle = null;
      this.provincesStyle = null;
    },
    //全部按钮
    allChange(){
      this.getSchoolList()
      this.provincesStyle = null
      this.City = ''
    },
    allCity(){
      this.CityStyle = null;
      this.cityS = ''
      const data = {
        province : this.provinceS
      }
      this.getSchoolList(data)
    },
    // 省份城市添加
    provinceAdd(e,index){
      this.CityNUM = this.options[index]
      this.addForm.province = e
      this.addForm.city = undefined
    },
    cityAdd(e){
      this.addForm.city  = e
    },
    //省份城市修改
    provinceEdit(e,index){
      this.CityNUM = this.options[index]
      this.editForm.province = e
    },
    cityEdit(e){
      this.editForm.city = e
    },
    // 省份城市选择查询
    async provincesChange(e,index){
      this.provincesStyle = index;
      this.City = this.options[index]
      this.CityStyle= null
      this.provinceS = e
      const data = {
        province : e
      }
      await this.getSchoolList(data)
      this.pagination.current = 1
    },
    // 城市选择
    async CityChange(e,index){
      this.CityStyle = index;
      this.cityS = e
      const data = {
        city : e,
        province : this.provinceS,
      }
      await this.getSchoolList(data)
      this.pagination.current = 1
    },
  }
}
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}
.school{
  margin: -15px -15px;
  background-color: whitesmoke;
  .searcherC{
    padding: 15px;
    margin: 0 auto;
    background-color: white;
    height: auto;
    font-size: 15px;
    .ant-btn{
      border: none !important;
      box-shadow:none !important;
    }
    .provinces{
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    .City{
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
    }
  }
  .Searcher{
    padding: 5px;
    height: 120px;
    width: 100%;
    margin: 10px auto;
    background-color: white;
    .searchH{
      margin: 20px;
      display: inline-flex;
      align-items: center;
    }
  }
  .school_list{
    background-color: white;
    margin: 0 auto;
    padding: 15px;
  }
}

</style>
